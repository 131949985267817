<template>
  <div class="layout-container py-40 md:py-56 md:px-56">
    <div v-if="data.LinkUrl || data.Heading" class="flex justify-between mb-24">
      <h5
        v-if="data.Heading"
        class="sub-heading-sm-text md:text-md"
        :style="`color: ${textColor}`"
      >
        {{ data.Heading }}
      </h5>
      <nuxt-link
        v-if="data.LinkUrl && data.LinkText"
        :to="data.LinkUrl"
        class="small-base-text md:text-base self-end"
        :style="`color: ${textColor}`"
      >
        {{ data.LinkText }}
      </nuxt-link>
    </div>
    <div class="-mx-24">
      <ClientOnly>
        <swiper
          :slides-per-view="'auto'"
          class="swiper-cover three-cover-slider"
        >
          <swiper-slide
            v-for="(coverBlock, index) in data.CoverBlocks"
            :key="index"
            class="
            swiper-slide
            relative
            px-6
            sm:px-12
            bg-center bg-no-repeat bg-cover
            "
            :class="{
              'cursor-pointer': coverBlock?.ContentLink?.Expanded?.LinkUrl,
            }"
            @click="onCoverClick(coverBlock?.ContentLink.Expanded)"
            data-epi-edit="backgroundMedia"
          >
            <video
              v-if="video(coverBlock?.ContentLink.Expanded)"
              class="absolute top-0 h-full object-cover left-6 sm:left-12"
              muted
              loop
              autoplay
              playsinline
            >
              <source
                :src="video(coverBlock?.ContentLink?.Expanded)"
                type="video/mp4"
              />
            </video>
            <div
              v-if="coverBlock && coverBlock?.ContentLink?.Expanded"
              class="relative p-24 bg-center bg-no-repeat bg-cover w-full items-end !min-h-380 sm:!min-h-500 flex md:p-32 aspect-[2/3]"
              :style="backgroundImage(coverBlock?.ContentLink.Expanded)"
              :class="{
                'sm:justify-start sm:items-start':
                  coverBlock?.ContentLink?.Expanded?.MainBodyPosition ===
                  'TopLeft',
                'sm:justify-center sm:text-center sm:items-start':
                  coverBlock?.ContentLink.Expanded.MainBodyPosition ===
                  'TopCenter',
                'sm:justify-end sm:items-start':
                  coverBlock?.ContentLink.Expanded.MainBodyPosition ===
                  'TopRight',
                'sm:justify-start sm:items-center':
                  coverBlock?.ContentLink.Expanded.MainBodyPosition ===
                  'LeftCenter',
                'sm:justify-center sm:items-center sm:text-center':
                  coverBlock?.ContentLink.Expanded.MainBodyPosition ===
                  'CenterCenter',
                'sm:justify-end sm:items-center':
                  coverBlock?.ContentLink.Expanded.MainBodyPosition ===
                  'RightCenter',
                'sm:justify-start sm:items-end':
                  coverBlock?.ContentLink.Expanded.MainBodyPosition ===
                  'BottomLeft',
                'sm:justify-center sm:items-end sm:text-center':
                  coverBlock?.ContentLink.Expanded.MainBodyPosition ===
                  'BottomCenter',
                'sm:justify-end sm:items-end':
                  coverBlock?.ContentLink.Expanded.MainBodyPosition ===
                  'BottomRight',
              }"
              data-epi-edit="MainBodyPosition"
            >
              <WysiwygWrapper
                :style-config="{ wysiwyg: true }"
                :html="coverBlock?.ContentLink.Expanded.MainBody"
                data-epi-edit="MainBody"
              ></WysiwygWrapper>
            </div>
          </swiper-slide>
        </swiper>
      </ClientOnly>
    </div>
  </div>
</template>
<script setup lang="ts">
import { type CoverBlockFragment } from '#gql';
import { useGlobalContentStore } from '~/store/globalContent';
import WysiwygWrapper from '~/components/WysiwygWrapper.vue';
import { type Cover3GridBlockFragment } from '#gql';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';

const config = useGlobalContentStore().config;
const { convertToRelativeUrl } = useStringExtensions();

const props = defineProps<{
  block: Cover3GridBlockFragment,
  isMobile: boolean,
  textColor?: string,
}>();

const data = computed(() => {
  if(props.block.LinkUrl) {
    props.block.LinkUrl = convertToRelativeUrl(props.block.LinkUrl);
  }
  return props.block as Cover3GridBlockFragment;
});

const backgroundImage = (block: CoverBlockFragment) => {
  if (block.BackgroundMediaMobile?.Expanded?.Url) {
    return `background-image: Url(${block.BackgroundMediaMobile.Expanded?.Url})`;
  }
  return ``;
};

const video = (block: CoverBlockFragment) => {
  if (block.BackgroundMediaMobile?.Expanded?.Url) {
    if (
      block.BackgroundMediaMobile?.Expanded?.ContentType &&
      block.BackgroundMediaMobile.Expanded?.ContentType[0] === 'Video') {
      return block.BackgroundMediaMobile.Expanded.Url;
    }
    return ``;
  }
};

const router = useRouter();

const onCoverClick = (coverBlock: CoverBlockFragment) => {
  if (coverBlock?.LinkUrl) {
    if (coverBlock?.LinkUrl?.includes(window.location.host)) {
      window.location.href = coverBlock?.LinkUrl;
    } else {
      router.push({ path: coverBlock?.LinkUrl });  
    }
  }
};
</script>

<style>
.swiper-cover {
  .swiper-container {
    @apply pr-32 sm:pr-0;
  }
  .swiper-wrapper {
    @apply w-auto -mx-6 sm:-mx-12 px-24;
  }
  video {
    width: calc(100% - 0.375rem * 2);
  
    @screen sm {
      width: calc(100% - 0.75rem * 2);
    }
  }
  &.three-cover-slider {
    .swiper-slide {
      @apply w-11/12 sm:w-3/5 md:w-1/3;
    }
  }
}

</style>
